import React from "react"
import Img from 'gatsby-image'

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export default function Banner(props) {
    const part1 = props.image.node.text.text;
    let part2;


    const Bold = ({ children }) => <span className="bold">{children}</span>
    const Text = ({ children }) => <p className="align-center">{children}</p>

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text className="py-10">{children}</Text>,
            [BLOCKS.EMBEDDED_ASSET]: node => {
                return (
                    <>
                        <h2>Embedded Asset</h2>
                        <pre>
                            <code>{JSON.stringify(node, null, 2)}</code>
                        </pre>
                    </>
                )
            },
        },
    }

    part2 = renderRichText(props.image.node.text2, options)

    return (
        <>
            <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-0 bg-flamingo-100">
                <div className="col-span-1">
                    <Img
                        alt={props.image.node.title}
                        fluid={props.image.node.banner.fluid}
                    />
                </div>
                <div className="flex flex-wrap bg-flamingo-100 content-center justify-center">
                    <div className="flex flex-col text-dustypurple p-4 sm:px-8 md:px-16">
                        <p className="text-3xl md:text-3xl lg:text-4xl xl:text-6xl py-2 sm:py-4 md:py-6 lg:py-8 xl:py-12">{props.image.node.title}</p>
                        <p className="md:text-sm lg:text-lg">{part1}</p>
                    </div>
                </div>
                <div className="md:col-span-2 2xl:col-span-1 p-4 sm:p-8 md:p-16 bg-gradient-to-tl from-white ">
                    <div className="md:text-sm lg:text-lg text-gray-600">
                        {part2}
                    </div>
                    <div className="text-center py-10">
                        <a href={`/${props.image.node.page}`} className="font-extrabold text-lg md:text-xl lg:text-2xl shadow-xl hover:shadow-lg  transition duration-500 ease-in-out transform scale-90 hover:scale-95 bg-dustyrose-50 text-white px-3 my-2 py-4 border-0">{props.image.node.button}</a>
                    </div>
                </div>
            </div>

            {/* <div className="h-12 sm:h-16 md:h-20 lg:h-28 bg-gray-50">
                <div className="flex flex-col p-4 sm:p-8 md:px-16 lg:py-16">
                    <p className="text-gray-800 text-1xl md:text-2xl lg:text-3xl">{props.image.node.header}</p>
                </div>
            </div> */}
        </>
    )
}



