import React from 'react'
import { graphql } from 'gatsby'
import Banner from '../components/banner'
import Layout from '../components/layout'
import get from 'lodash/get'

// import styles from './hero.module.css'

export default class Home extends React.Component { // ({data}) {

  render() {
    const landing = get(this, 'props.data.allContentfulLandingPage.edges')

    return (
      <Layout page="home">
        {landing.map(banner => (<Banner image={banner}></Banner>))}
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulLandingPage(
      limit: 5
      sort: {fields: orderNo, order: ASC}
      filter: {isCurrent: {eq: true}}
    ) {
      edges {
        node {
          id
          isCurrent
          title
          page
          button
          text {
            id
            text
          }
          text2 {
            raw
          }
          header
          updatedAt
          banner {
            fluid(resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }  
  }
`